import React from 'react';

import "swiper/css";
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";


import TestimonialsData from '../../data/testimonials/testimonialsData.json';

const Testimonials = () => {
    return (
        <section id="testimonials" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="title">
                    <h2>What Clients say?</h2>
                </div>

                <div className="description max-width-2">
                    <p>Happy Clients We've Worked With.</p>
                </div>

                <div className="spacer p-top-lg testimonial">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        grabCursor={true}
                        loop={true}
                        speed={500}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                            }
                        }}
                        modules={[Pagination, Autoplay]}
                        className="testimonial-swiper"
                    >
                        {
                            TestimonialsData.map((data, key) => (
                                <SwiperSlide>
                                    <figure class="snip1390 bg-dark">
                                        <img src={data.avatar} alt={data.name} className="profile" />
                                        <figcaption>
                                            <h5 className='text-white mb-0'>{data.name}</h5>
                                            {data.subName && <p>{data.subName}</p>}
                                            <blockquote>{data.message}</blockquote>
                                        </figcaption>
                                    </figure>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
