import React from 'react';
import { Link } from "react-router-dom";

const BackToHome = () => {
    return (
        <div className="button">
            <Link title="Back to homepage" className="btn btn-outline-primary text-uppercase" to={"/"}>Back to home</Link>
        </div>
    );
};

export default BackToHome;
