import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import AboutContent from "../blocks/about/AboutContent";
import PageTitleAbout from "../blocks/page-title/PageTitleAbout";

const About = () => {
  document.body.className = "";
  document.body.classList.add("page");

  return (
    <Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>About | Resham Gurung</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>

      <main id="main" className="site-main">
        <PageTitleAbout />

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <div id="about-img" className="block">
                  <div className="about-img">
                    <div className="img object-fit">
                      <div className="object-fit-cover">
                        <img src="/assets/img/bg/about.jpg" alt="About" />
                      </div>
                    </div>
                  </div>
                </div>

                <AboutContent />

              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default About;
