import ReactCompareImage from 'react-compare-image';

function PortfolioCompare({ portfolio }) {

    return (
        <section id="page-album" className="block spacer p-top-xl">
            <div className="wrapper">
                {
                    portfolio.beforeAfter.map((item, key) => {
                        return (
                            <div className='container mb-4' key={key}>
                                <ReactCompareImage
                                    leftImage={item.before}
                                    rightImage={item.after}
                                    leftImageLabel="Before"
                                    rightImageLabel="After" />
                            </div>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default PortfolioCompare;