import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import AboutContent from "../blocks/about/AboutContent";
import AboutServices from "../blocks/about/AboutServices";
import ContactMe2 from "../blocks/contacts/ContactMe2";
import ContactMe from "../blocks/home/ContactMe";
import Testimonials from "../blocks/home/Testimonials";
import PageTitleHome from "../blocks/page-title/PageTitleHome";

const Home = () => {
  document.body.className = "";
  document.body.classList.add("home");

  return (
    <Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Home | Resham Gurung</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>

      <main id="main" className="site-main pt-0">
        <div className="content">
          <div className="clearfix">
            <PageTitleHome />

            <div id="about" className="block spacer p-top-xl">
              <div className="wrapper">
                <div className="title">
                  <h2>What I do?</h2>
                </div>
                <div className="description max-width-2">
                  <p>
                    At Resham Gurung, we are passionate about transforming moments into timeless memories. Our team of skilled photographers
                    is dedicated to delivering exceptional quality and personalized service to every client.
                  </p>
                </div>
                <div className="spacer p-top-lg">
                  <AboutServices />
                </div>
              </div>
            </div>

            <Testimonials />

            <ContactMe />


            <div id="page-content" className="spacer p-top-xl">
              <div className="wrapper">
                <div className="content">
                  <div className="clearfix">
                    <div id="about-img" className="block">
                      <div className="about-img">
                        <div className="img object-fit">
                          <div className="object-fit-cover">
                            <img src="/assets/img/bg/about.jpg" alt="About" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <AboutContent />

                  </div>
                </div>
              </div>
            </div>

            <section id="contact-me-2" className="block spacer p-top-xl">
              <div className="wrapper">
                <ContactMe2 />
              </div>
            </section>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Home;
