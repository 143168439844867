import React from 'react';
import { Link } from "react-router-dom";

const PortfolioPagination = ({ pagination, parent }) => {
    return (
        <div id="pagination" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="portfolio-pagination">
                    <div className="row justify-content-between gutter-width-md with-pb-lg">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {pagination.previous && (
                                <Link className="d-flex justify-content-start link previous-link" to={`/${parent}/` + pagination.previous.link}>
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="left">
                                            <p><i className="fas fa-long-arrow-alt-left"></i></p>
                                        </div>

                                        <div className="right">
                                            <p className="link-text after">Previous</p>
                                            <h4 className="link-title">{pagination.previous.title}</h4>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            {pagination.next && (
                                <Link className="d-flex justify-content-end link next-link" to={`/${parent}/` + pagination.next.link}>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <div className="left">
                                            <p className="link-text after">Next</p>
                                            <h4 className="link-title">{pagination.next.title}</h4>
                                        </div>

                                        <div className="right">
                                            <p><i className="fas fa-long-arrow-alt-right"></i></p>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioPagination;
