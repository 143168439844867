import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Page404 from "./pages/404";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import ServiceInside, { loader as serviceLoader } from "./pages/ServiceInside";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'service/:serviceId',
        element: <ServiceInside />,
        loader: serviceLoader
      },
      {
        path: 'contacts',
        element: <Contacts />
      },
    ]
  }
]);

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
      <Analytics />
      <SpeedInsights />
    </HelmetProvider>
  );
}

export default App;
