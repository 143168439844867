import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";


import ModalMenuPrimary from '../../blocks/menu/MenuModalPrimary';

const MenuModal = () => {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();

    useEffect(() => {
        close();
        window.scrollTo(0, 0);
    }, [location]);

    const open = () => {
        setShowModal(true);
    };

    const close = () => {
        setShowModal(false);
    };

    return (
        <div className="menu-toggle no-space">
            <button onClick={open} type="button" className="btn btn-link border-0 p-0 min-w-auto" data-toggle="modal" data-target="#menu-modal">
                <span className="adveits-i adv-hamburger">
                    <span></span>
                    <span></span>
                </span>
            </button>

            <Modal
                className="modal fade shift-modal"
                id="menu-modal"
                show={showModal}
                onHide={close}
                backdrop={false}
                aria-labelledby="menu-modal"
                aria-hidden="true"
                dialogClassName="modal-dialog modal-full wrapper"
            >
                <Modal.Header className="modal-header-top">
                    <Link title="Logo" className="logo logo-primary transform-scale-h" to={"/"}>
                        <img src="/assets/img/logo/logo.png" alt="Logo" />
                    </Link>

                    <button onClick={close} type="button" className="close btn btn-link border-0 min-w-auto" data-dismiss="modal" aria-label="Close">
                        <span className="adveits-i adv-close">
                            <span></span>
                            <span></span>
                        </span>
                    </button>
                </Modal.Header>

                <Modal.Body className="modal-body-centered">
                    <div className="d-flex justify-content-center w-100">
                        <div className="menu-img">
                            <img src="/assets/img/menu/menu-img.png" alt="Menu" />
                        </div>

                        <div className="animated fadeindown">
                            <ModalMenuPrimary />

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MenuModal;
