import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

import ContactMe2 from "../blocks/contacts/ContactMe2";
import ContactsList from "../blocks/contacts/ContactsList";
import PageTitleContacts from "../blocks/page-title/PageTitleContacts";

const Contacts = () => {
  document.body.className = "";
  document.body.classList.add("page");

  return (
    <Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Contacts | Resham Gurung</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>

      <main id="main" className="site-main">
        <PageTitleContacts />

        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <ContactsList />

                <div id="contact-me-2" className="block spacer p-top-xl">
                  <ContactMe2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Contacts;
