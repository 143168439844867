import { Outlet } from "react-router-dom";
import Footer from "../blocks/footer/Footer";
import Header from "../blocks/header/Header";
import Loading from '../blocks/loading/Loading';

const Layout = () => {
  return (
    <>
      <Header />
      <Loading />

      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
