import React from 'react';

const AboutContent = () => {
    return (
        <div id="about" className="block spacer p-top-xl">
            <div className="title">
                <h2>About me</h2>
            </div>

            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>I do all kinds of photo editing, retouching, manipulations, etc.</h5>
                </div>

                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>We specialize in capturing life's moments with a creative and professional touch. With extensive experience across various fields, including event photography, social media coordination, and production management, we are dedicated to providing high-quality visual content and exceptional service. Our expertise includes meticulous photo editing, social media strategy, event promotion, and live streaming, ensuring that every project we undertake is executed to perfection.</p>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
