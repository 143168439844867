import React from 'react';
import { Link } from "react-router-dom";

const HeaderLogo = ({ image }) => {
    return (
        <div className="header-logo">
            <Link className="logo logo-primary transform-scale-h" title="Logo" to={"/"}>
                <img src="/assets/img/logo/logo.png" alt="Logo" />
            </Link>
        </div>
    );
};

export default HeaderLogo;
