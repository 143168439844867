import React from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const PageTitleHome = () => {
  return (
    <section id="page-title" className="block">
      <div className="position-relative">
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          speed={500}
          loop={true}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Autoplay]}
          className="banner-swiper"
        >
          <SwiperSlide>
            <img src="/assets/img/banner/1.jpg" alt="Home Banner 1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/img/banner/2.jpg" alt="Home Banner 2" />
          </SwiperSlide>
        </Swiper>
        <div className="home-bg" />
        <div className="home-banner wrapper">
          <div className="page-title-content">
            <div className="page-title-description">
              <div className="title">
                <h1 className="large">
                  Seeking Photoshop Wizards?
                </h1>
              </div>

              <div className="description">
                <p>
                  Elevate your images with our editing mastery!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitleHome;
