import React from "react";

const PageTitleContacts = () => {
  return (
    <section id="page-title">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">Contacts.</h1>
        </div>

        <div className="description-2">
          <p>
            Get in touch with Resham Gurung for all your photography needs. Our team is ready to provide personalized solutions and answer
            any questions you may have.{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitleContacts;
