import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useLoaderData } from "react-router-dom";

import PortfolioAlbum from "../blocks/portfolio/PortfolioAlbum";
import PortfolioCompare from "../blocks/portfolio/PortfolioCompare";
import PortfolioPagination from "../blocks/portfolio/PortfolioPagination";

import ServicesData from "../data/services/servicesData";

export function loader({ params }) {
  const selectedIndex = ServicesData.findIndex((x) => x.link === params.serviceId);
  const portfolio = ServicesData[selectedIndex];

  let previous = null;
  let next = null;
  const length = ServicesData.length;

  if (selectedIndex === -1) {
    return { portfolio, pagination: { previous: null, next: null } };
  }

  const prevIndex = (selectedIndex - 1 + length) % length;
  const nextIndex = (selectedIndex + 1) % length;

  previous = ServicesData[prevIndex];
  next = ServicesData[nextIndex];

  return {
    portfolio,
    pagination: {
      previous,
      next,
    },
  };
}

const ServiceInside = () => {
  document.body.className = "";
  document.body.classList.add("single");
  document.body.classList.add("single-portfolio");
  const { portfolio, pagination } = useLoaderData();
  return (
    <Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Service | Resham Gurung</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>

      <main id="main" className="site-main">
        <div id="page-content">
          <div className="content">
            <div id="single">
              <div className="single-content">
                <section id="page-title" className="block">
                  <div className="wrapper">
                    <div className="page-title-content">
                      <div className="page-title-description pl-0 text-center">
                        <div className="title">
                          <h1 className="large">{portfolio.title}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {
                  portfolio.gallery?.length && <PortfolioAlbum portfolio={portfolio} />
                }

                {portfolio.beforeAfter?.length && <PortfolioCompare portfolio={portfolio} />}

                <PortfolioPagination pagination={pagination} parent={"service"} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default ServiceInside;
