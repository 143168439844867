import React from "react";
import { Link, useLocation } from "react-router-dom";

const ModalMenuPrimary = () => {
  let location = useLocation();
  return (
    <nav className="menu-primary">
      <ul className="nav flex-column">
        <li
          className={
            "nav-item " +
            (location.pathname === "/" ? "current-nav-item" : "")
          }
        >
          <Link title="Home" to={"/"}>
            Home
          </Link>
        </li>

        <li className={"nav-item " + (location.pathname.includes("/about") ? "current-nav-item" : "")}>
          <Link title="About" to={"/about"}>
            About
          </Link>
        </li>

        <li className={"nav-item " + (location.pathname.includes("/contacts") ? "current-nav-item" : "")}>
          <Link title="Contacts" to={"/contacts"}>
            Contacts
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default ModalMenuPrimary;
